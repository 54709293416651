main{
    background: $bg-light;
    padding:4rem 0;
}

@media screen and (max-width:768px){
    main{
        background: $bg-light;
        padding: 20px 0;
    }
}
@media screen and (max-width:575px){
    main{
        padding: 10px 0;
    }
}