section{
    padding: 6rem 0;
}
section h1{
    @include f-36-primary-bold;
    text-align: center;
    margin-bottom: 2rem;
    position: relative;

    &::after{
        content: "";
        position: absolute;
        left: 50%;
        bottom: 0;
        height: 3px;
        width: 40px;
        transform: translateX(-50%);
        background: $bg-brand;
    }
}
.o-sectionSimilar{
    background: $bg-light;
    border-top: 1px solid $border-grey;
}