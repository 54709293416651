li{
    @include f-14-primary-regular;
    padding: 10px 0 10px 20px;
    list-style: none;
    position: relative;

    &::before{
        content: "";
        left: 0;
        top: 50%;
        position: absolute;
        transform: translateY(-50%);
        height: 10px;
        width: 10px;
        border: 2px solid $border-brand;
        border-radius: 50%;
    }
}