.m-categoryCell{
    background: $bg-white;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    transition: 0.3s ease all;
    margin: 10px 0;
    border-radius: $border-radius-primary;
    flex-wrap: wrap;
    height: 220px;
    position: relative;
    z-index: 1;

    &:hover, &:active, &:focus{
        text-decoration: none;
        box-shadow: $box-shadow-primary;
    }
    &::after{
        content: "";
        background: rgba(0,0,0,0.2);
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
.m-categoryCell__img{
    height: 100%;
}
.m-categoryCell__title{
    @include f-20-white-medium;
    margin: 0;
    margin-left: 0;
    text-align: center;
    z-index: 2;
    margin-bottom: 20px;
}