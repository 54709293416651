a {
    color: $brand-primary;
    font-weight: 500;
    font-size: 1.5rem;

    &:hover, &:focus, &:active{
        color: $text-primary;
        outline: none;
    }
}

