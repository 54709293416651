.o-cartUsefInfo{
    .o-cartUsefInfo__form{
        margin: 0;
        padding-bottom: 1px;
    }
}
.o-cartUsefNotes{
    background: $bg-white;
    padding: 20px;
    width: 100%;
    margin-bottom: 4rem;
}