#cookie-bar{
	background: $bg-primary;
}
#cookie-bar .cb-enable {
    background: $brand-primary;

    &:hover, &:active, &:focus{
        background: $brand-secondary;
    }
}
#cookie-bar .cb-policy {
    background: $bg-dark;
}

@media screen and (max-width: 768px){
    #cookie-bar.fixed.bottom {
        bottom: 60px;
        width: 320px;
        max-width: 90%;
        left: auto;
        right: 0px; 
        top: auto;
        border-radius: $border-radius-primary;
    }
    #cookie-bar .cb-enable {
        background: $brand-primary;
        display: inline-block;
        width: 45%;
    }
    #cookie-bar .cb-policy {
        background: $bg-medium;
        display: inline-block;
        width: 40%;
    }
}