.o-pageHeading{
    background: $bg-primary;
    padding: 10rem 0;

    &::after{
        content: "";
        @include absolute-position(0,0,0,0);
        background: rgba(0,0,0,0.2);
    }
    &.-light{
        background: $bg-white;

        &::after{
            content: none;
        }
        .o-pageHeading__title{
            @include f-48-primary-bold;
            font-size: 4rem;
            
            &::after {
                content: none;
            }
        }
        .m-breadcrumb{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
    }
}
.o-pageHeading__container{
    position: relative;
    z-index: 2;
    text-align: center;
}
.o-pageHeading__title{
    @include f-48-white-bold;
    margin: 0;
    display: inline-block;

    &.-search{
        font-size: 3.6rem;
        background: $bg-brand;
        padding: 10px 20px;
    }
}
.o-pageHeading__text{
    @include f-16-white-regular;
    margin: 0;
    text-align: center;
    margin-top: 2rem;
}

@media (max-width: 991px){
    .o-pageHeading{
        padding: 10rem 0 5rem 0;   
    }
    .o-pageHeading__title{
        font-size: 3.6rem;
    }
}