.o-cartDeliveryForm{

}
.o-cartDeliveryForm__title{
    @include f-36-primary-bold;
    margin: 4rem 0 2rem 0;
}
.o-cartDeliveryForm__col{
    margin: 4rem 0;
}
@media screen and (max-width: 768px){
    .o-cartDeliveryForm__title{
        text-align: center;
    }
}