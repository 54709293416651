.m-navUser{
    background: transparent;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 3rem;
    transition: 0.2s ease all;
    position: relative;
    cursor: pointer;
    border-left: 1px solid rgba(255,255,255,0.1);

    &:hover, &:active, &:focus{
        background: $bg-dark;
        text-decoration: none;
    }
    &[aria-expanded="true"]{
        background: $bg-dark;
        text-decoration: none;
    }
}
.m-navUser__icon{
    height: 22px;
    width: 22px;
}
.m-navUser__info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 15px;
    height: 100%;
}
.m-navUser__text{
    @include f-14-white-regular;
}
.m-navUser__title{
    @include f-16-white-medium;
    max-width: 160px;
    overflow: hidden; 
    text-overflow: ellipsis;
    white-space: nowrap;
}
.m-navUser__collapse{
    position: absolute;
    top: 100%;
    left: 0;
    background: $bg-white;
    box-shadow: $box-shadow-primary;
    width: 280px;
}   
.m-navUser__collapseLink{
    height: 5.4rem;
    padding: 0 20px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:hover, &:active, &:focus{
        background: $bg-light;
        text-decoration: none;
    }
}

@media screen and (max-width:991px){
    .m-navUser{
        width: 56px;
        padding: 0;
        display: none;
    }
    .m-navUser__info{
        display: none;
    }
}