.m-tabsNav{
    background: $bg-white;
}
.m-tabsNav__nav{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 0;
}
.m-tabsNav__link{
    background: transparent;
    border: 0;
    min-height: 5.4rem;
    padding: 0 2.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include f-16-medium-medium;

    &:hover, &:active, &:focus{
        text-decoration: none;
        background: $bg-light;
    }
}
.m-tabsNav__link.active{
    background: $bg-light;
    color: $text-primary;
}





.m-tabsContent{
    background: $bg-light;
    padding: 4rem 0;
}
.m-tabsContent__btnWrap{
    text-align: center;
    padding-bottom: 2rem;
    margin-top: 3rem;
}
.m-tabsContent__collapseToggle{
    padding: 15px 20px;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-top: 2px solid $border-white;
    background: $bg-light;
    @include f-14-primary-medium;
}
.m-tabsContent__collapse{
    
}
@media screen and (min-width:501px){
    .m-tabsContent__collapse{
        display: block !important;
    }
}

@media screen and (max-width:991px){
    .m-tabsContent{
        padding: 7px 10px;
    }
}
@media screen and (max-width:500px){
    .m-tabsNav__nav{
        display: none;
    }
    .m-tabsContent{
        padding: 0;
        margin: 0 10px;
    }
    .m-tabsContent__collapseToggle{
        display: flex;
    }
    .m-tabsContent__pane{
        display: block !important;
        opacity: 1 !important;
    }
    .m-tabsContent__collapse .container{
        padding: 5px 10px;
        border-top: 2px solid $border-white;
    }
}

/* IE 10, 11 CSS */ 
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
    .m-tabsNav__link{
        height: 5.4rem;
    }
}