
@font-face {
    font-family: Renner;
    font-weight: 400;
    src: url("../fonts/Jost-400-Book.otf") format("opentype");
}

@font-face {
    font-family: Renner;
    font-weight: 500;
    src: url("../fonts/Jost-500-Medium.otf") format("opentype");
}

@font-face {
    font-family: Renner;
    font-weight: 700;
    src: url("../fonts/Jost-700-Bold.otf") format("opentype");
}