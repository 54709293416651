.container{
    max-width: 1220px;
    padding-right: 20px;
    padding-left: 20px;
}
@media screen and (max-width:768px){
    .container{
        padding-right: 10px;
        padding-left: 10px;
    }
}