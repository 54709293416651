/*------------------------------------*\
    ARTICLE
\*------------------------------------*/

main article{
    margin: 0 auto;
    padding: 2rem 0;
    display: block;
    background: $bg-light;
    font-size: 1.6rem;
}
@media screen and (max-width:575px){
    main article{
        padding: 2rem 0;
        background: $bg-light;
    }
}

article{
    & p, & b, & i, & u, & strong, & a{
        line-height: 3rem;
        font-size: 1.6rem;
        display: inline-block;
        font-weight: 400;
    }
    & > p, & > b, & > i, & > u, & > strong{
        margin-bottom: 3rem;
    }
    & b, & strong{
        font-weight: 500;
    }
    & p{
        margin-bottom: 3rem;
        display: block;
    }
    & p:last-child{
        margin-bottom: 0rem;
    }
    & a{
        font-weight: 500;
    }
}

/*------------------------------------*\
    ARTICLE POST
\*------------------------------------*/

article.post{
    & p, & b, & i, & u, & a, & strong{
        font-family: $font-family-primary;
        line-height: 3rem;
        font-size: 1.6rem;
        font-weight: 400;
    }
    & b, & strong{
        font-weight: 500;
    }
    & .article-bottom{
        border-top: 1px solid rgba(0,0,0,0.1);
        padding-top: 4rem;
        margin-top: 4rem;

        & a{
            font-size: 1.6rem;
            line-height: 1.2;
            font-weight: 500;
        }
    } 
}
article{
    &.post {
        @for $index from 1 through 6 {
          & h#{$index}{
            line-height: 1.5;
            font-weight: 500;
            font-family: $font-family-primary;
          }
        }
        & h1{
            font-size: 3.6rem;
        }
        & h2{
            font-size: 3rem;
        }
        & h3{
            font-size: 2.4rem;
        }
        & h4{
            font-size: 2rem;
        }
        & h5{
            font-size: 1.8rem;
        }
        & h6{
            font-size: 1.6rem;
        }
    }
}

/*------------------------------------*\
    ARTICLE ELEMENT STYLES
\*------------------------------------*/

.post-back-btn{
	margin-top: 4rem;
}

.page-contact__col{
	margin: 2rem 0;

	& h3{
		font-size: 2.4rem;
		font-family: $font-family-primary;
		font-weight: 500;
		margin-bottom: 1rem;
		padding-bottom: 1rem;
		position: relative;

		&::after{
			content: "";
			position: absolute;
			background: $brand-primary;
			width: 50px;
			height: 2px;
			bottom: 0;
			left: 0;
		}
	}
	& p, & strong{
		display: block;
		margin-bottom: 1rem;
	}
}
.page-contact__map{
	margin: 2rem 0;
}
.page-contact__img{
	display: block;
	text-align: center;
}

@media screen and (max-width:575px){
	.page-contact__col{
		margin: 0 0 4rem 0;
	}
}


.order-history{
    & h3{
        padding-bottom: 1.5rem;
        border-bottom: 2px solid #2b2f30;
        margin: 0;
        margin-bottom: 2.5rem;
        font-size: 2.25rem;
    }
}

.order-history-single-wrap{
    display: table;
    width: 100%;
}
.order-history-single{
    display: table-row;

    &.head p{
        font-weight: 500;
    }
}
.order-history-single-col{
    display: table-cell;
    padding: 10px;
    border-bottom: 1px solid $border-primary;

    & a{
        display: block;
        margin: 5px 0;
        &:last-child{
            margin: 0;
        }
    }
    & p{
        margin: 0;
        line-height: 1.5;
        margin: 5px 0;
    }
    & .price{
        color: $text-red;
        font-weight: 500;
    }
    & .status{
        color: $text-green;
        font-weight: 500;
    }
}
@media screen and (max-width: 991px){
    .order-history{
        & h3{
            display: none;
        }
        & .login-form-toggler{
            display: block;
            width: 100%;
            margin: 0;
        }
    }
}
@media screen and (min-width: 992px){
    .order-history .collapse {
        display: block;
    }
}
@media screen and (max-width: 768px){
    .order-history-single.head{
        display: none;
    }
    .order-history-single {
        padding: 20px 0;
        display: block;
        border-bottom: 3px solid $border-primary;
    }
    .order-history-single-col{
        display: block;
        max-width: 100%;

        &:last-child{
            border: 0;
        }
    }
}

.form-link__forgotten-pass{
    display: block;
    text-align: center;
}
.forgotten-password{
    background: $bg-light;
    padding: 3rem;
    text-align: left;
    margin: 0 auto;
}
.forgotten-password_text{
    margin-bottom: 3rem;
}


.page-message{
    display: block;
    padding: 0rem;
    text-align: left;
    margin: 0 auto;
}
.page-message__title{
    text-align: center;
    font-size: 3rem;
    margin-bottom: 3rem;
    font-family: $font-family-secondary;
    font-weight: 500;
}
.page-message__buttons{
    margin: 0 auto;
    display: block;
    text-align: center;
}
.page-message__btn{
margin: 10px ;
}
.page-message__login-form{
    max-width: 100%;
    width: 660px;
    margin: 0 auto;
    padding: 2rem;
    background: $bg-light;
    border-radius: $border-radius-primary;
}
@media screen and (max-width: 500px){
    .page-message__login-form{
        padding: 10px;
    }
}


.photogallery{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
}
.photogallery__single{
    width: 20%;
    padding: 10px 5px 0px 5px;
}
.photogallery__figure{
    margin: 0;
    position: relative;
    padding-bottom: 100%;
}
.photogallery__link{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include absolute-position(0,0,0,0);
    background: $bg-white;
}
.photogallery__img{
    max-width: 100%;
    max-height: 100%;
}
.photogallery__figcaption{
    position: absolute;
    bottom: 0;
    font-size: 1.4rem;
    background: rgba(0,0,0,0.5);
    left: 0;
    right: 0;
    color: $text-white;
    overflow: hidden;
    padding: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    opacity: 0;
    transition: 0.3s ease all;
}
.photogallery__figure{
    &:hover, &:focus, &:active{
        & .photogallery__figcaption{
            opacity: 1;
        }
    }
}
@media screen and (max-width: 991px){
    .photogallery__single{
        width: 25%;
        padding: 10px 5px 0px 5px;
    }
}
@media screen and (max-width: 768px){
    .photogallery__single{
        width: 33.333%;
        padding: 10px 5px 0px 5px;
    }
}
@media screen and (max-width: 500px){
    .photogallery__single{
        width: 50%;
        padding: 10px 5px 0px 5px;
    }
}

.user-detail__tab-wrap{
    padding: 2rem;
    border: 1px solid $border-primary;
    border-top: 0;
}

.a-newsBtn{
    text-align: center;
    display: block;
    margin-top: 3rem;
}