.m-navLogo{
    padding: 0 3rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-right: 1px solid rgba(255,255,255,0.1);
}
.m-navLogo__link{
    height: 90px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.m-navLogo__img{
    height: 100%;
}

@media screen and (max-width:991px){
    .m-navLogo{
        padding: 0 0 0 10px;
        border: 0;
    }
    .m-navLogo__link{
        justify-content: flex-start;
        height: 40px;
    }
}
@media screen and (max-width:500px){
    .m-navLogo{
        padding: 0;
        padding-left: 10px;
    }
}