.o-nav{
    background: $bg-dark;
    background-image: url("../img/bg-transparent.png");
    background-position: bottom center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 122px;
}
.o-navMain{
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    z-index: 6;
}
.o-navMain__inner{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    z-index: 5;
}
@media screen and (max-width:991px){
    .o-nav{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 56px;
        z-index: 8;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    }
    .o-navMain__inner{
        justify-content: flex-end;
        border: 0;
    }
}
