/*------------------------------------*\
    DROPDOWNS
\*------------------------------------*/

.dropdown-toggle{
    &::after{
        content: none;
    }
    & p{
        margin: 0;
    }
}
.dropdown-item.active, .dropdown-item:active {
    color: $text-white;
    text-decoration: none;
    background-color: $brand-primary;
}