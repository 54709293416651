figure{
    margin: 0;
}
picture{

}
img {
    max-width: 100%;
    height: auto;
    transition: 0.3s opacity ease;
}
figcaption{

}