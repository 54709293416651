.m-newsSingle{
    background: transparent;
    color: $text-primary;
    overflow: hidden;
    margin: 10px 0;
    width: 100%;
    display: block;

    &:hover, &:active, &:focus{
        color: $text-primary;
        text-decoration: none;

        .m-newsSingle__img{
           opacity: 0.8;
        }
    }
}
.m-newsSingle__top{
    position: relative;
    min-height: 240px;
    width: 100%;
    background: $bg-light;
    overflow: hidden;
    border-radius: $border-radius-primary;
}
.m-newsSingle__img{
    z-index: 0;
    transition: 0.5s ease all;
}
.m-newsSingle__dateWrap{
    position: absolute;
    top: 10px;
    left: 10px;
    background: rgba(0,0,0,0.5);
    padding: 0px 12px;
    height: 30px;
    border-radius: $border-radius-primary;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.m-newsSingle__date{
    @include f-14-white-medium;
}
.m-newsSingle__bottom{
    padding: 1.5rem 0;
    background: transparent;
}
.m-newsSingle__title{
    @include f-18-primary-medium;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;  
    margin: 0;
}
