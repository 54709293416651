.a-tag{
    background: $bg-primary;
    @include f-12-white-medium;
    height: 24px;
    padding: 0 10px 0 0px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    position: relative;
    margin-left: 24px;

    &::after{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        transform: translateX(-100%);
        height: 100%;
        width: 24px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center center; 
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAyNSAyNSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjEuNDE0MjE7Ij48cmVjdCBpZD0iQXJ0Ym9hcmQxIiB4PSIwIiB5PSIwIiB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHN0eWxlPSJmaWxsOm5vbmU7Ii8+PGNsaXBQYXRoIGlkPSJfY2xpcDEiPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIyNSIgaGVpZ2h0PSIyNSIvPjwvY2xpcFBhdGg+PGcgY2xpcC1wYXRoPSJ1cmwoI19jbGlwMSkiPjxwYXRoIGlkPSJQYXRoXzE4IiBkPSJNMjUuMjczLDBsLTEwLjgxMywwLjAxOWMtMS4wNjcsMC4wMjcgLTIuMTE4LDAuMjYzIC0zLjA5NSwwLjY5N2MtMC45OSwwLjM4MSAtMS44OTYsMC45NTMgLTIuNjY3LDEuNjgybC03LjczLDcuNzMyYy0wLjYzNywwLjYyNSAtMC45ODcsMS40ODggLTAuOTY3LDIuMzc5Yy0wLjAyMSwwLjg5MiAwLjMyOSwxLjc1NCAwLjk2NywyLjM3OWw3LjczMiw3LjczM2MwLjc3LDAuNzI5IDEuNjc2LDEuMzAxIDIuNjY3LDEuNjgyYzAuOTY4LDAuNDMyIDIuMDE1LDAuNjYyIDMuMDc2LDAuNjc4bDEwLjg0NywwLjAxOWwtMC4wMTcsLTI1Wm0tOS4zNDEsMTIuNTA5YzAuMDE0LDAuODk1IC0wLjM0MywxLjc1NiAtMC45ODUsMi4zNzljLTAuNjIzLDAuNjQyIC0xLjQ4NSwwLjk5OSAtMi4zNzksMC45ODVjLTAuODk1LDAuMDE0IC0xLjc1NywtMC4zNDMgLTIuMzgsLTAuOTg2Yy0wLjY0MiwtMC42MjMgLTEsLTEuNDg0IC0wLjk4NSwtMi4zNzljLTAuMDE0LC0wLjg5NSAwLjM0MywtMS43NTUgMC45ODUsLTIuMzc4YzAuNjIzLC0wLjY0MyAxLjQ4NSwtMSAyLjM4LC0wLjk4NWMwLjg5NCwtMC4wMTUgMS43NTYsMC4zNDIgMi4zNzksMC45ODVjMC42NDIsMC42MjMgMSwxLjQ4NCAwLjk4NSwyLjM3OVoiIHN0eWxlPSJmaWxsOiMyNDI3MmQ7ZmlsbC1ydWxlOm5vbnplcm87Ii8+PC9nPjwvc3ZnPg==");     
    }
}
.a-tag.-sale{
    background: $bg-red;

    &::after{
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAyNSAyNSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjEuNDE0MjE7Ij48cmVjdCBpZD0iQXJ0Ym9hcmQxIiB4PSIwIiB5PSIwIiB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHN0eWxlPSJmaWxsOm5vbmU7Ii8+PGNsaXBQYXRoIGlkPSJfY2xpcDEiPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIyNSIgaGVpZ2h0PSIyNSIvPjwvY2xpcFBhdGg+PGcgY2xpcC1wYXRoPSJ1cmwoI19jbGlwMSkiPjxwYXRoIGlkPSJQYXRoXzE4IiBkPSJNMjUuMjczLDBsLTEwLjgxMywwLjAxOWMtMS4wNjcsMC4wMjcgLTIuMTE4LDAuMjYzIC0zLjA5NSwwLjY5N2MtMC45OSwwLjM4MSAtMS44OTYsMC45NTMgLTIuNjY3LDEuNjgybC03LjczLDcuNzMyYy0wLjYzNywwLjYyNSAtMC45ODcsMS40ODggLTAuOTY3LDIuMzc5Yy0wLjAyMSwwLjg5MiAwLjMyOSwxLjc1NCAwLjk2NywyLjM3OWw3LjczMiw3LjczM2MwLjc3LDAuNzI5IDEuNjc2LDEuMzAxIDIuNjY3LDEuNjgyYzAuOTY4LDAuNDMyIDIuMDE1LDAuNjYyIDMuMDc2LDAuNjc4bDEwLjg0NywwLjAxOWwtMC4wMTcsLTI1Wm0tOS4zNDEsMTIuNTA5YzAuMDE0LDAuODk1IC0wLjM0MywxLjc1NiAtMC45ODUsMi4zNzljLTAuNjIzLDAuNjQyIC0xLjQ4NSwwLjk5OSAtMi4zNzksMC45ODVjLTAuODk1LDAuMDE0IC0xLjc1NywtMC4zNDMgLTIuMzgsLTAuOTg2Yy0wLjY0MiwtMC42MjMgLTEsLTEuNDg0IC0wLjk4NSwtMi4zNzljLTAuMDE0LC0wLjg5NSAwLjM0MywtMS43NTUgMC45ODUsLTIuMzc4YzAuNjIzLC0wLjY0MyAxLjQ4NSwtMSAyLjM4LC0wLjk4NWMwLjg5NCwtMC4wMTUgMS43NTYsMC4zNDIgMi4zNzksMC45ODVjMC42NDIsMC42MjMgMSwxLjQ4NCAwLjk4NSwyLjM3OVoiIHN0eWxlPSJmaWxsOiNjNDFlM2Q7ZmlsbC1ydWxlOm5vbnplcm87Ii8+PC9nPjwvc3ZnPg==");      
    }
}
.a-tag.-new{
    background: $bg-green;

    &::after{
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAyNSAyNSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjEuNDE0MjE7Ij48cmVjdCBpZD0iQXJ0Ym9hcmQxIiB4PSIwIiB5PSIwIiB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHN0eWxlPSJmaWxsOm5vbmU7Ii8+PGNsaXBQYXRoIGlkPSJfY2xpcDEiPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIyNSIgaGVpZ2h0PSIyNSIvPjwvY2xpcFBhdGg+PGcgY2xpcC1wYXRoPSJ1cmwoI19jbGlwMSkiPjxwYXRoIGlkPSJQYXRoXzE4IiBkPSJNMjUuMjczLDBsLTEwLjgxMywwLjAxOWMtMS4wNjcsMC4wMjcgLTIuMTE4LDAuMjYzIC0zLjA5NSwwLjY5N2MtMC45OSwwLjM4MSAtMS44OTYsMC45NTMgLTIuNjY3LDEuNjgybC03LjczLDcuNzMyYy0wLjYzNywwLjYyNSAtMC45ODcsMS40ODggLTAuOTY3LDIuMzc5Yy0wLjAyMSwwLjg5MiAwLjMyOSwxLjc1NCAwLjk2NywyLjM3OWw3LjczMiw3LjczM2MwLjc3LDAuNzI5IDEuNjc2LDEuMzAxIDIuNjY3LDEuNjgyYzAuOTY4LDAuNDMyIDIuMDE1LDAuNjYyIDMuMDc2LDAuNjc4bDEwLjg0NywwLjAxOWwtMC4wMTcsLTI1Wm0tOS4zNDEsMTIuNTA5YzAuMDE0LDAuODk1IC0wLjM0MywxLjc1NiAtMC45ODUsMi4zNzljLTAuNjIzLDAuNjQyIC0xLjQ4NSwwLjk5OSAtMi4zNzksMC45ODVjLTAuODk1LDAuMDE0IC0xLjc1NywtMC4zNDMgLTIuMzgsLTAuOTg2Yy0wLjY0MiwtMC42MjMgLTEsLTEuNDg0IC0wLjk4NSwtMi4zNzljLTAuMDE0LC0wLjg5NSAwLjM0MywtMS43NTUgMC45ODUsLTIuMzc4YzAuNjIzLC0wLjY0MyAxLjQ4NSwtMSAyLjM4LC0wLjk4NWMwLjg5NCwtMC4wMTUgMS43NTYsMC4zNDIgMi4zNzksMC45ODVjMC42NDIsMC42MjMgMSwxLjQ4NCAwLjk4NSwyLjM3OVoiIHN0eWxlPSJmaWxsOiM5QUE4MjA7ZmlsbC1ydWxlOm5vbnplcm87Ii8+PC9nPjwvc3ZnPg==");      
    }
}
.a-tag.-recommended{
    background: $bg-primary;

    &::after{
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAyNSAyNSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjEuNDE0MjE7Ij48cmVjdCBpZD0iQXJ0Ym9hcmQxIiB4PSIwIiB5PSIwIiB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHN0eWxlPSJmaWxsOm5vbmU7Ii8+PGNsaXBQYXRoIGlkPSJfY2xpcDEiPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIyNSIgaGVpZ2h0PSIyNSIvPjwvY2xpcFBhdGg+PGcgY2xpcC1wYXRoPSJ1cmwoI19jbGlwMSkiPjxwYXRoIGlkPSJQYXRoXzE4IiBkPSJNMjUuMjczLDBsLTEwLjgxMywwLjAxOWMtMS4wNjcsMC4wMjcgLTIuMTE4LDAuMjYzIC0zLjA5NSwwLjY5N2MtMC45OSwwLjM4MSAtMS44OTYsMC45NTMgLTIuNjY3LDEuNjgybC03LjczLDcuNzMyYy0wLjYzNywwLjYyNSAtMC45ODcsMS40ODggLTAuOTY3LDIuMzc5Yy0wLjAyMSwwLjg5MiAwLjMyOSwxLjc1NCAwLjk2NywyLjM3OWw3LjczMiw3LjczM2MwLjc3LDAuNzI5IDEuNjc2LDEuMzAxIDIuNjY3LDEuNjgyYzAuOTY4LDAuNDMyIDIuMDE1LDAuNjYyIDMuMDc2LDAuNjc4bDEwLjg0NywwLjAxOWwtMC4wMTcsLTI1Wm0tOS4zNDEsMTIuNTA5YzAuMDE0LDAuODk1IC0wLjM0MywxLjc1NiAtMC45ODUsMi4zNzljLTAuNjIzLDAuNjQyIC0xLjQ4NSwwLjk5OSAtMi4zNzksMC45ODVjLTAuODk1LDAuMDE0IC0xLjc1NywtMC4zNDMgLTIuMzgsLTAuOTg2Yy0wLjY0MiwtMC42MjMgLTEsLTEuNDg0IC0wLjk4NSwtMi4zNzljLTAuMDE0LC0wLjg5NSAwLjM0MywtMS43NTUgMC45ODUsLTIuMzc4YzAuNjIzLC0wLjY0MyAxLjQ4NSwtMSAyLjM4LC0wLjk4NWMwLjg5NCwtMC4wMTUgMS43NTYsMC4zNDIgMi4zNzksMC45ODVjMC42NDIsMC42MjMgMSwxLjQ4NCAwLjk4NSwyLjM3OVoiIHN0eWxlPSJmaWxsOiMzMDMyMUM7ZmlsbC1ydWxlOm5vbnplcm87Ii8+PC9nPjwvc3ZnPg==");      
    }
}
.a-tag.-top{
    background: $bg-yellow;

    &::after{
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAyNSAyNSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjEuNDE0MjE7Ij48cmVjdCBpZD0iQXJ0Ym9hcmQxIiB4PSIwIiB5PSIwIiB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHN0eWxlPSJmaWxsOm5vbmU7Ii8+PGNsaXBQYXRoIGlkPSJfY2xpcDEiPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIyNSIgaGVpZ2h0PSIyNSIvPjwvY2xpcFBhdGg+PGcgY2xpcC1wYXRoPSJ1cmwoI19jbGlwMSkiPjxwYXRoIGlkPSJQYXRoXzE4IiBkPSJNMjUuMjczLDBsLTEwLjgxMywwLjAxOWMtMS4wNjcsMC4wMjcgLTIuMTE4LDAuMjYzIC0zLjA5NSwwLjY5N2MtMC45OSwwLjM4MSAtMS44OTYsMC45NTMgLTIuNjY3LDEuNjgybC03LjczLDcuNzMyYy0wLjYzNywwLjYyNSAtMC45ODcsMS40ODggLTAuOTY3LDIuMzc5Yy0wLjAyMSwwLjg5MiAwLjMyOSwxLjc1NCAwLjk2NywyLjM3OWw3LjczMiw3LjczM2MwLjc3LDAuNzI5IDEuNjc2LDEuMzAxIDIuNjY3LDEuNjgyYzAuOTY4LDAuNDMyIDIuMDE1LDAuNjYyIDMuMDc2LDAuNjc4bDEwLjg0NywwLjAxOWwtMC4wMTcsLTI1Wm0tOS4zNDEsMTIuNTA5YzAuMDE0LDAuODk1IC0wLjM0MywxLjc1NiAtMC45ODUsMi4zNzljLTAuNjIzLDAuNjQyIC0xLjQ4NSwwLjk5OSAtMi4zNzksMC45ODVjLTAuODk1LDAuMDE0IC0xLjc1NywtMC4zNDMgLTIuMzgsLTAuOTg2Yy0wLjY0MiwtMC42MjMgLTEsLTEuNDg0IC0wLjk4NSwtMi4zNzljLTAuMDE0LC0wLjg5NSAwLjM0MywtMS43NTUgMC45ODUsLTIuMzc4YzAuNjIzLC0wLjY0MyAxLjQ4NSwtMSAyLjM4LC0wLjk4NWMwLjg5NCwtMC4wMTUgMS43NTYsMC4zNDIgMi4zNzksMC45ODVjMC42NDIsMC42MjMgMSwxLjQ4NCAwLjk4NSwyLjM3OVoiIHN0eWxlPSJmaWxsOiNmZmIzMDA7ZmlsbC1ydWxlOm5vbnplcm87Ii8+PC9nPjwvc3ZnPg==");      
    }
}

.a-tag.-circle{
    background: $bg-red;
    border-radius: 50%;
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: $text-white;
    height: 40px;
    width: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 2;
    padding: 0;
    margin: 0;

    &::after{
        content: none;
    }
}