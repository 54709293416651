.m-contactItem{
    height: 100%;
    overflow: hidden;
    padding: 20px 0;
}
.m-contactItem__title{
    background: transparent;
    @include f-24-primary-medium;
    padding: 15px 0px;
    margin: 0;
    font-weight: 700;
}
.m-contactItem_content{
    padding: 0px;
    background: transparent;
    height: 100%;
    border-bottom-right-radius: $border-radius-primary;
    border-bottom-left-radius: $border-radius-primary;
}
.contactItem__subtitle{
    @include f-16-primary-medium; 
    display: block;
}
.m-contactItem__phoneWrap{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 0;
}
.m-contactItem__icon {
    margin-right: 10px;
    opacity: 0.75;
}
.m-contactItem__phone{
    @include f-24-primary-medium; 
    color: $text-green;
    margin: 0;
    display: block;
}
.m-contactItem__subtitle{
    margin-top: 2rem;
}
.m-contactItem__text{
    @include f-16-dark-regular; 
    margin: 0;
    line-height: 1.75;
}
.m-contactItem__button{
    text-align: center;
    margin-top: 20px;
}
.m-contactItem__link{
    display: block;
}