.scrollTop{
    display: block;
    position: fixed;
    right: 3vw;
    bottom: 3vw;
    background: $bg-light;
    padding: 18px;
    border-radius: 999px;
    cursor: pointer;
    z-index: 8;
    transition: 0.3s ease all;

    &:hover{
        box-shadow: 0px,0,10px,rgba(0,0,0,0.25);
    }
    & span{
        height: 10px;
        width: 10px;
    }
}
@media screen and (max-width:1400px){
    .scrollTop{
        display: none;
    }
}