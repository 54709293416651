.m-cartDeliveryItem{
    position: relative;
    background: $bg-white;
    padding: 6rem 2rem 2rem 2rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: $border-radius-primary;
    cursor: pointer;
    transition: 0.2s ease all;
    margin: 0;

    &:hover, &:active, &:focus{
        box-shadow: $box-shadow-primary;
    }
}
.m-cartDeliveryItem__input{
    opacity: 0;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
}
.m-cartDeliveryItem__iconWrap{
    border-radius: 50%;
    height: 80px;
    width: 80px;
    background: $bg-white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 4px solid $bg-light;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 2;
    transition: 0.2s ease all;
}
.m-cartDeliveryItem__icon{
    height: 30px;
    width: 30px;
    transition: 0.2s ease all;

    &.-white{
        display: none;
    }
}
.m-cartDeliveryItem__title{
    @include f-18-primary-medium;
    color: $text-brand;
    text-align: center;
    z-index: 2;
    position: relative;
}
.m-cartDeliveryItem__text{
    @include f-14-dark-regular;
    text-align: center;
    margin-bottom: 2rem;
    z-index: 2;
    position: relative;
}
.m-cartDeliveryItem__price{
    width: 100%;
    z-index: 2;
    position: relative;
}
.m-cartDeliveryItem__top::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $bg-brand-secondary;
    border-radius: $border-radius-primary;
    z-index: -1;
    transition: 0.2s ease all;
    opacity: 0;
}

.m-cartDeliveryItem__input:checked{

    & ~ .m-cartDeliveryItem__iconWrap{
        background: $bg-brand-secondary;

        .m-cartDeliveryItem__icon{
            display: none;
        
            &.-white{
                display: block;
            }
        }
    }
    & ~ .m-cartDeliveryItem__top::after{
        z-index: 1;
        opacity: 1;
    }
    & ~ .m-cartDeliveryItem__top .m-cartDeliveryItem__title, & ~ .m-cartDeliveryItem__top .m-cartDeliveryItem__text{
        color: $text-white;
    }
    & ~ .m-cartDeliveryItem__bottom .m-cartDeliveryItem__price{
        color: $text-white;
        background: rgba(0,0,0,0.15);
    }
}

.m-cartDeliveryItem__input:disabled{
    & ~ .m-cartDeliveryItem__iconWrap .m-cartDeliveryItem__icon{
        opacity: 0.5;
    }
    & ~ .m-cartDeliveryItem__top .m-cartDeliveryItem__title, & ~ .m-cartDeliveryItem__top .m-cartDeliveryItem__text, & ~ .m-cartDeliveryItem__bottom .m-cartDeliveryItem__price{
        color: $text-medium; 
    }
    & ~ .m-cartDeliveryItem__bottom .m-cartDeliveryItem__price::after{
        background: rgba(0,0,0,0.5);
    }
}